import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { FormValidationModalComponent } from '../form-validation-modal/form-validation-modal.component';
declare var $: any;

@Component({
  selector: 'vc-form-builder',
  templateUrl: './form-builder.component.html',
  styleUrls: ['./form-builder.component.scss']
})
export class FormBuilderComponent implements AfterViewInit, OnDestroy {
  eventId: string = 'VdoUjTaifwXRdVsI'; // TODO get eventId from service
  formBuilder: any;
  surveyTitle: string = '';
  options = {
    // defaultFields: [
    //   {
    //     "type": "button",
    //     "subtype": "submit",
    //     "label": "Submit",
    //     "className": "btn-success btn",
    //     "name": "button-1599651839264",
    //     "access": false,
    //     "style": "success"
    //   }
    // ],
    disableFields: ['file'],
    onSave: () => {
      // check if form has a submit button
      if (!this.formBuilder.formData) {
        return;
      }
      let hasSubmit:boolean = false;
      JSON.parse(this.formBuilder.formData).forEach(element => {
        if ((element.type === 'button') && (element.subtype === 'submit')) {
          hasSubmit = true;
        }
      });
      if (!hasSubmit) {
        this.toastr.error('The form has to contain a button of the type "submit".');
        return;
      }
      if (!this.surveyTitle) {
        this.toastr.error('Missing survey name.');
        return;
      }
      this.openNewChannelModal(this.formBuilder.formData);
    },
  };

  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService,
    private modalService: BsModalService
  ) { }

  ngOnInit() {
   if (this.authService.getEventRole(this.eventId) != 'admin') {
    this.router.navigateByUrl('/');
   }
  }

  ngOnDestroy() {
  }

  ngAfterViewInit() {
    this.formBuilder = $('#formBuilder').formBuilder(this.options);
    this.formBuilder.promise.then(() => {
      const btnMid: HTMLElement = document.querySelector('.form-actions.btn-group .btn.btn-default');
      if (btnMid && btnMid.innerText === "[{…}]") btnMid.remove();
    });
  }
  
  openNewChannelModal(formData: any) {
    const modalRef: BsModalRef = this.modalService.show(FormValidationModalComponent);
    modalRef.content.formData = JSON.parse(formData);
    modalRef.content.eventId = this.eventId;
    modalRef.content.surveyTitle = this.surveyTitle;
    modalRef.content.buildValidations();
  }

}
