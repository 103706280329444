import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
// Services
import { SurveyService } from '../../services/survey/survey.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Component({
  selector: 'vc-form-validation-modal',
  templateUrl: './form-validation-modal.component.html',
  styleUrls: ['./form-validation-modal.component.scss']
})
export class FormValidationModalComponent {
  eventId: string;
  surveyTitle: string;
  formData: any[];
  validations: any = {};
  ready: boolean = false;

  constructor(
    private router: Router,
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private surveyService: SurveyService,
  ) { }

  buildValidations(formData: any, ) {
    if (this.formData) {
      this.formData.forEach(field => {
        this.validations[field.name] = {
          "min-count": {
            active: false,
            value: null
          },
          "max-count": {
            active: false,
            value: null
          },
          "number-min": {
            active: (field.type === 'number') && field.min ? true : false,
            value: field.type === 'number' ? field.min : null
          },
          "number-max": {
            active: (field.type === 'number') && field.max ? true : false,
            value: field.type === 'number' ? field.max : null
          },
          "date-min": {
            active: false,
            value: null
          },
          "date-max": {
            active: false,
            value: null
          },
          "regex": {
            active: false,
            value: null
          }
        };
      });
      console.log(this.validations);
      this.ready = true;
    }
  }

  setFieldLabel(fieldName:string, fieldLabel:string) {
    this.validations[fieldName].label = fieldLabel;
    return fieldLabel;
  }

  displayValidationOption(fieldType: string, allowedTypes: string[]) {
    return allowedTypes.includes(fieldType);
  }

  hideValidationOption(fieldType: string, disallowedTypes: string[]) {
    return !disallowedTypes.includes(fieldType);
  }

  parseValidationRules() {
    const keys:string[] = Object.keys(this.validations);
    const values:any[] = Object.values(this.validations);
    // validation
    values.forEach((value) => {
      if (value["min-count"].active && value["max-count"].active) {
        if (value["min-count"].value >= value["max-count"].value) {
          this.toastr.error(`${value.label}: "min-count" has to be smaller than "max-count".`);
          return;
        } else {
          console.log(`${value.label}: min-count and max-count ROGER`);
        }
      }
      if (value["number-min"].active && value["number-max"].active) {
        if (value["number-min"].value >= value["number-max"].value) {
          this.toastr.error(`${value.label}: "number-min" has to be smaller than "number-max".`);
          return;
        } else {
          console.log(`${value.label}: number-min and number-max ROGER`);
        }
      }
      if (value["date-min"].active && value["date-max"].active) {
        if (new Date(value["date-min"].value).getTime() >= new Date(value["date-max"].value).getTime()) {
          this.toastr.error(`${value.label}: "date-min" has to be smaller than "date-max".`);
          return;
        } else {
          console.log(`${value.label}: date-min and date-max ROGER`);
        }
      }
    });
    // parsing
    values.forEach((value, index) => {      
      const fD = this.formData.find(e => e.name === keys[index]);
      if (!fD) return;
      fD.validation = [];
      if (value["min-count"].active) fD.validation.push(["min-count", value["min-count"].value, `You have to select a minimum of ${value["min-count"].value} options for "${value.label}".`]);
      if (value["max-count"].active) fD.validation.push(["max-count", value["max-count"].value, `You can only select a maximum of ${value["max-count"].value} options for "${value.label}".`]);
      if (value["number-min"].active) {
        fD.validation.push(["number-min", value["number-min"].value, `The value of "${value.label}" has to be a minimum of ${value["number-min"].value}.`]);
        fD.min = value["number-min"].value;
      }
      if (value["number-max"].active) {
        fD.validation.push(["number-max", value["number-max"].value, `The value of "${value.label}" can not be higher than ${value["number-max"].value}.`]);
        fD.max = value["number-max"].value;
      }
      if (value["date-min"].active) fD.validation.push(["date-min", value["date-min"].value, `Min date is set to ${value["date-min"].value} for "${value.label}".`]);
      if (value["date-max"].active) fD.validation.push(["date-max", value["date-max"].value, `Max date is set to ${value["date-max"].value} for "${value.label}".`]);
      if (value["regex"].active) fD.validation.push(["regex", value["regex"].value, `Your input for ${value.label} does not match with the requirements.`]);
    });
    this.createSurvey();
  }

  createSurvey() {
    this.surveyService.create({
      event_id: this.eventId,
      label: this.surveyTitle,
      public: true,
      formData: this.formData
    }).subscribe(
      data => {
        this.toastr.success(`Survey has been created!`);
        this.bsModalRef.hide();
        this.router.navigate(['/survey', data._id]);
      },
      error => {
        console.error('Error', error);
        this.toastr.error(`There was an error during the survey's creation!`);
      }
    );
  }

}
