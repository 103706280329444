import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
// Services
import { ChannelService } from 'src/app/services/channel/channel.service';

@Component({
  selector: 'vc-channel-create-modal',
  templateUrl: './channel-create-modal.component.html',
  styleUrls: ['./channel-create-modal.component.scss']
})
export class ChannelCreateModalComponent {
  event_id: string;
  channelName: string;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    public bsModalRef: BsModalRef,
    private channelService: ChannelService,
  ) {}

  createChannel() {
    if (!this.event_id || !this.channelName) {
        this.toastr.warning('Not all required information is availabel.', 'Missing information.');
        return;
    }
    this.channelService.create({
        event_id: this.event_id,
        active_session_id: null,
        name: this.channelName
    }).subscribe(
        data => {
            this.toastr.success(`Created channel "${this.channelName}".`);
            this.router.navigate(['event', this.event_id, 'channel', data._id]).then(() => this.bsModalRef.hide());
        },
        error => {
            this.toastr.error(`Could not create channel "${this.channelName}".`, 'Error');
            console.error('[channel-create.modal]', error)
        }
    )
  }

}
