import { Component, OnInit } from '@angular/core';
import { AuthService } from '../services/auth.service';

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {
  userRoles = [];
  logedInUser = null;
  event_id = "VdoUjTaifwXRdVsI";
  workshop1: boolean = false;
  workshop2: boolean = false;
  workshop3: boolean = false;

  constructor(private authService: AuthService,) { }

  ngOnInit() {
    // this.userRoles = this.authService.getEventRole(this.event_id);
    // console.log("User Roles", this.userRoles)
    this.logedInUser = this.authService.getUser();
    // console.log("loged in user",this.logedInUser)
    this.userRoles = this.logedInUser.role;
    console.log("userRoles :)", this.userRoles)
  }

}
