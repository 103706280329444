import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import { Subject } from 'rxjs';
import { Event } from './event';

@Injectable()
export class EventService extends BaseService<Event> {
  activeEvent: Event;
  $activeEvent: Subject<Event> = new Subject();

  constructor(
    protected feathers: Feathers
  ) {
    super(feathers, 'event');
  }

}
