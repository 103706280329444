import { Component, ViewEncapsulation } from '@angular/core';
import { Router, NavigationStart } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
// Services
import { AuthService } from './services/auth.service';
import { AnalyticsService } from './services/analytics.service';
import { EventService } from './services/event/event.service';
// Components
import { CookieModalComponent } from './components/cookie-modal/cookie-modal.component';
import { LivechatService } from './services/livechat/livechat.service';

@Component({
  selector: 'vc-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AppComponent {
  activeUrl: string;
  loggedIn: boolean = false;
  activeEvent: any = {_id: "VdoUjTaifwXRdVsI"};
  isCollapsed:boolean = true;
  bsModalRef: BsModalRef;

  constructor(
    private router: Router,
    private toastr: ToastrService,
    private authService: AuthService,
    private eventService: EventService,
    private analyticsService: AnalyticsService,
    private modalService: BsModalService,
    private liveChatService: LivechatService,
  ) {}

  ngOnInit() {
    this.authService.logIn().then(data => {
      if (data && data.user) {
        this.loggedIn = true;
      } else {
        this.loggedIn = false
      }
    });
    this.eventService.$activeEvent.subscribe(data => {
      // angular lifecylce timing workaround
      setTimeout(() => this.activeEvent = data, 0);
    });
    // cookies
    const cookie = document.cookie.match(/(;)?vcCookieSettings=([^;]*);?/);
    if (!cookie) {
      this.openCookieModal();
      // tracking
      this.router.events.subscribe(
        (event: any) => {
          if(event instanceof NavigationStart) {
            this.analyticsService.trackView(event);
          }
      });
    }
    // technical support
    this.liveChatService.createTawkChat('5f1019bb67771f3813c1253c/1f867l0n6');
  }

  toggleNav = () => this.isCollapsed ? this.isCollapsed = false : this.isCollapsed = true;

  logout() {
    this.authService.logOut();
  }

  openCookieModal() {
    const initialState = {};
    this.bsModalRef = this.modalService.show(CookieModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

}
