import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService } from 'ngx-bootstrap';
import { CookieModalComponent } from '../cookie-modal/cookie-modal.component';

@Component({
  selector: 'app-cookie-page',
  templateUrl: './cookie-page.component.html',
  styleUrls: ['./cookie-page.component.scss']
})
export class CookiePageComponent implements OnInit {
  bsModalRef: BsModalRef;

  constructor(
    private modalService: BsModalService,
  ) { }

  ngOnInit() {
  }
  
  openCookieModal() {
    const initialState = {};
    this.bsModalRef = this.modalService.show(CookieModalComponent, {
      initialState,
      ignoreBackdropClick: true,
      keyboard: false
    });
  }

}
