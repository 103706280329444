import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import { Survey } from './survey';

@Injectable()
export class SurveyService extends BaseService<Survey> {

  constructor(
    protected feathers: Feathers
  ) {
    super(feathers, 'survey');
  }

}
