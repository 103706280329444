import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
// Services
import { FileService } from '../../services/file/file.service';
import { ToastrService } from 'ngx-toastr';
import { Session } from '../../services/session/session';

@Component({
    selector: 'vc-session-modal',
    templateUrl: './session-modal.component.html',
    styleUrls: ['./session-modal.component.scss']
})

export class SessionModalComponent {
  session: Session;
  list: any[] = [];

  constructor(
    public bsModalRef: BsModalRef,
    public fileService: FileService,
    private toastr: ToastrService,
  ) {}

  downloadFile(upload:any) {
    const toast = this.toastr.info(`Download started: ${upload.upload_id}`);
    this.fileService.download(upload.upload_id).subscribe(
      data => {
        this.toastr.remove(toast.toastId);
        this.toastr.success(`Downloaded file ${upload.upload_id}.`)
    } ,
      error => {
        this.toastr.error(`Could not download file file ${upload.upload_id}.`);
        console.error(error);
      }
    );
  }

}
