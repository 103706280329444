import { Component } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Channel } from '../../services/channel/channel';
import { Session } from '../../services/session/session';

@Component({
  selector: 'app-channel-edit-modal',
  templateUrl: './channel-edit-modal.component.html',
  styleUrls: ['./channel-edit-modal.component.scss']
})
export class ChannelEditModalComponent {
  channel:Channel;
  sessions: Session;

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

}
