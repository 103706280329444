import { Component, AfterViewInit, OnDestroy } from '@angular/core';
import { LivechatService } from '../../services/livechat/livechat.service';
declare var LiveAgent: any;

@Component({
  selector: 'vc-tagungbuero',
  templateUrl: './tagungsbuero.component.html',
  styleUrls: ['./tagungsbuero.component.scss'],
})
export class TagungsbueroComponent implements AfterViewInit, OnDestroy {

  constructor(
    private liveChatService: LivechatService,
  ) { }

  ngAfterViewInit() {
    this.liveChatService.createLiveChat('', 'tawk');
  }

  ngOnDestroy() {
    this.liveChatService.cleanupLiveChat();
  }

}
