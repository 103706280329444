import { Injectable } from '@angular/core';
import { Feathers } from './feathers.service';
import { Observable, from } from 'rxjs';
import { map, take } from 'rxjs/operators';

@Injectable()
export class BaseService<E> {
  service;

  constructor(
    protected feathers: Feathers,
    protected serviceName: string,
  ) {
    this.service = this.feathers.service(serviceName);
  }

  watch(filter?:any): Observable<E[]> {
    // just returning the observable will query the backend on every subscription
    // using some caching mechanism would be wise in more complex applications
    return this.service.watch().find(filter).pipe(
      map((e:any) => e.data),
    );
  }

  get(id:string, filter?:any): Observable<E> {
    return this.service.watch().get(id, filter).pipe(take(1));
  }

  create(data:any): Observable<E> {
    return from(this.service.create(data)).pipe(take(1), map(e => <E>e));
  }

  replace(id:string, data:any): Observable<E> {
    return from(this.service.update(id, data)).pipe(take(1), map(e => <E>e));
  }

  patch(id:string, data:any): Observable<E> {
    return from(this.service.patch(id, data)).pipe(take(1), map(e => <E>e));
  }

  remove(id: string, options?:any): Observable<E> {
    return from(this.service.remove(id, options)).pipe(take(1), map(e => <E>e));
  }

}
