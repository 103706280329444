import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap';
import { StringifyOptions } from 'querystring';

@Component({
  selector: 'app-cookie-modal',
  templateUrl: './cookie-modal.component.html',
  styleUrls: ['./cookie-modal.component.scss']
})
export class CookieModalComponent implements OnInit {
  modalTitle: string = '🍪 Cookie consent management';
  modalText: string = `
    <p><strong>We need your consent</strong></p>
    <p>We need your consent to the use of cookies and other technologies by us and our partners to store and process personal data on your device. This includes, among other things, recognition features that serve to provide you with the best possible user experience on this site. In the following you will find an overview of the purposes for which we process your data.</p>
  `;
  customClass: string = 'panelClass';
  cookies;
  consentRequests: any =  [
    {
      title: 'Required cookies',
      html: 'This site uses realtime communication services and provides persisten user session. For these required functionality we need to store a session identifier. Please refer to the <a href="https://www.zwo-null.de/datenschutzerklaerung.html" target="_blank">legal information</a> of zwonull.media for more information.',
      name: 'feathers',
      required: true,
      value: true,
      cookieNames: [],
      sessionStorage: [],
      localStorage: []
    },
    {
      title: 'Statistics',
      html: 'This site uses analytical tracking to gather usage statistics for this page. Please refer to the <a href="https://www.zwo-null.de/datenschutzerklaerung.html" target="_blank">legal information</a> of zwonull.media for more information.<br><br><a href="https://piwik.zwo-null.de/index.php?module=CoreAdminHome&action=optOut" target="_blank">Manage Matomo Analystics by zwonull.media</a>',
      name: 'matomo,umami',
      required: false,
      value: false,
      cookieNames: ['zenMode', 'MATOMO_SESSID', 'mtm_consent_removed', '_pk_(*)'],
      // cookieNames: ['zenMode', 'MATOMO_SESSID', 'mtm_consent_removed', '_pk_(*)', 'umami.auth'],
      sessionStorage: [],
      localStorage: []
    },
    {
      title: 'Livechat via Tawk.to',
      html: 'This site uses <a href="//tawk.to" target="_blank">tawk.to</a> to provide livechat widgets. Without this consent we can not provide these functionality. Please refer to the <a href="https://www.tawk.to/legal/" target="_blank">legal information</a> of tawk.to for more information.',
      name: 'tawk',
      required: false,
      value: false,
      cookieNames: ['TawkConnectionTime', '__tawkuuid'],
      sessionStorage: ['TawkWindowName'],
      localStorage: ['twk_(*)']
    }
  ];

  constructor(
    public bsModalRef: BsModalRef,
  ) { }

  ngOnInit() {
    this.cookies = this.getCookies();
    const item = localStorage.getItem('vcCookieSettings');
    if (item) {
      this.restorePreferences(JSON.parse(atob(item)));
    }
  }

  getCookies() {
    const cookies = { };
    document.cookie.split(';').forEach((e:any) => {
      e = e.split('=');
      cookies[e[0].trim()] = e[1];
    })
    return cookies;
  }

  deleteCookie(cookie: string) {
    document.cookie = document.cookie + cookie + "=;expires=Thu, 01 Jan 1970 00:00:00 GMT";
  }

  deleteStorage(type: string = 'localStorage', key: string) {
    if (!key) return;
    localStorage.removeItem(key);
  }

  cleanProvider(provider: any) {
    provider.cookieNames.forEach((cookie: string) => {
      if (cookie.includes('(*)')) {
        Object.keys(this.cookies).forEach(key => {
          if (key.includes(cookie.replace('(*)', ''))) {
            this.deleteCookie(key);
          }
        });
      } else {
        this.deleteCookie(cookie)
      }
    });
    provider.sessionStorage.forEach((marker: string) => {
      if (marker.includes('(*)')) {
        Object.keys(sessionStorage).forEach(key => {
          if (key.includes(marker.replace('(*)', ''))) {
            this.deleteStorage('sessionStorage', key);
          }
        });
      } else {
        this.deleteStorage('sessionStorage', marker);
      }
    });
    provider.localStorage.forEach((marker: string) => {
      if (marker.includes('(*)')) {
        Object.keys(localStorage).forEach(key => {
          if (key.includes(marker.replace('(*)', ''))) {
            this.deleteStorage('localStorage', key);
          }
        });
      } else {
        this.deleteStorage('localStorage', marker);
      }
    });
  }

  acceptSelected() {
    this.consentRequests.forEach(provider => {
      if (!provider.value) {
        this.cleanProvider(provider)
      }
    });
    this.savePreferences();
    this.bsModalRef.hide();
  }

  acceptAll() {
    this.consentRequests = this.consentRequests.map(p => {
      p.value = true;
      return p;
    });
    this.savePreferences();
    this.bsModalRef.hide();
  }

  savePreferences() {
    let allowedCookies: string[] =  [];
    let state = this.consentRequests.map(e => {
      if (e.value) {
        allowedCookies.push(e.name);
      }
      return {
        title: e.title,
        value: e.value,
        cookieNames : e.cookieNames,
        sessionStorage : e.sessionStorage,
        localStorage : e.localStorage,
      }
    });
    state = btoa(JSON.stringify(state));
    const cookieValue = allowedCookies.join('.').replace(/,/g, '.');
    document.cookie = "vcCookieSettings=" + cookieValue + "; max-age=" + 30*24*60*60+";";
    localStorage.setItem('vcCookieSettings', state);
    window.location.reload();
  }

  restorePreferences(state) {
    state.forEach(savedPreference => {
      const index = this.consentRequests.findIndex(e => e.title === savedPreference.title);
      if (index > -1) {
        this.consentRequests[index].value = savedPreference.value;
        if (!savedPreference.value) {
          this.cleanProvider(savedPreference);
        }
      }
    });
  }

}
