import { Injectable } from '@angular/core';
import { DateTime, IANAZone } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class DateTimeService {
  localTimezone: string;

  constructor() {
    this.localTimezone =  Intl.DateTimeFormat().resolvedOptions().timeZone;
  }

  updateDayOfDateTime(dateTime: Date | DateTime, newDay: Date| DateTime) {   
    console.log('dateTime', dateTime);
    console.log('newDay', newDay)
    try {
      dateTime = DateTime.fromJSDate(<Date>dateTime);
      newDay = DateTime.fromJSDate(<Date>newDay);
    } catch (e) {
      throw new Error(e);
    }
    console.log('Before:', dateTime.toISO());
    dateTime = dateTime.set({day: newDay.day, month: newDay.month, year: newDay.year});
    console.log('After:', dateTime.toISO());
    return dateTime;
  }

  fromTimezoneToTimezone(date: Date, targetTimezone: string = 'UTC', originTimezone: string = this.localTimezone) {
    if (!IANAZone.isValidZone(targetTimezone) || !IANAZone.isValidZone(originTimezone)) {
      throw new Error('Timezones are invalid.')
    }
    const dateTime = DateTime.fromISO(date.toISOString(), {zone:originTimezone});
    return dateTime.setZone(targetTimezone);
  }

}
