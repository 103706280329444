import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Exhibitor } from '../../services/exhibitor/exhibitor';
import { ExhibitorService } from '../../services/exhibitor/exhibitor.service';

@Component({
  selector: 'vc-sponsor',
  templateUrl: './sponsor.component.html',
  styleUrls: ['./sponsor.component.scss']
})
export class SponsorComponent implements OnInit {
  exhibitors: Exhibitor[] = [];

  constructor(
    private sanitizer: DomSanitizer,
    private exhibitorService: ExhibitorService,
  ) { }

  ngOnInit() {
     this.exhibitorService.get().subscribe(data => this.exhibitors = data);
  }

}
