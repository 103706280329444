import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import { take } from 'rxjs/operators';
import { UploadInEvent } from './upload-in-event';

@Injectable()
export class UploadInEventService extends BaseService<UploadInEvent> {

  constructor(
    protected feathers: Feathers
  ) {
    super(feathers, 'upload-in-event');
  }
  
  getUploadsBySession(sessionId:string, limit:number = 100) {
    return super.watch({
      query: {
        session_id: sessionId,
        $limit: limit,
      }
    }).pipe(
      take(1)
    );
  }

}
