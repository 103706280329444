import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
// Model
import { Session } from '../../services/session/session';
// Services
import { SessionService } from '../../services/session/session.service';

@Component({
  selector: 'vc-session-edit-modal',
  templateUrl: './session-edit-modal.component.html',
  styleUrls: ['./session-edit-modal.component.scss']
})
export class SessionEditModalComponent {
  session: Session;

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private sessionService: SessionService,
  ) {}

}
