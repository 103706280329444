import { Injectable } from '@angular/core';
import { Feathers } from '../feathers.service';
import { BaseService } from '../base.service';
import { forkJoin, Observable, throwError } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { AuthService } from '../auth.service';
import { Message } from './message';
import { DomSanitizer } from '@angular/platform-browser';
import { SecurityContext } from '@angular/core';

@Injectable()
export class MessageService extends BaseService<Message> {
  constructor(
    protected feathers: Feathers,
    protected http: HttpClient,
    protected auth: AuthService,
    private _sanitizer: DomSanitizer,
  ) {
    super(feathers, 'messages');
  }

  getAllMessages(session_id: string, limit: number = 50): Observable<Message[]> {
    const options: any = { 
      headers :  new HttpHeaders({
        'Content-Type': 'application/json',
        'Authorization': this.auth.getAccessToken()
      }),
      params: new HttpParams().set('session_id', session_id).set('$limit', String(0))
    }
    return this.http.get(`${this.feathers.instanceUrl}/messages`, options).pipe(
      switchMap((data: any) => {
        let obs = [];
        const header: any = { 
          headers :  new HttpHeaders({
            'Content-Type': 'application/json',
            'Authorization': this.auth.getAccessToken()
          })
        }
        const maxI = Math.round(data.total/limit) > 0 ? Math.round(data.total/limit) : 1;
        for (let i = 0; i < maxI; i++) {
          obs.push(
            this.http.get(`${this.feathers.instanceUrl}/messages`, Object.assign(header, {params: new HttpParams().set('session_id', session_id).set('$limit', String(limit)).set('$skip', String(i*limit))}))
          );
        }
        return forkJoin(...obs).pipe(map(e => {
          return [].concat(...e.map(e => e.data))
        }));
      })
    );
  }

  watch(filter ? : any): Observable <Message[]> {
    const defaultFilter = {
      query: {
        $sort: {
          createdAt: -1
        },
        $limit: 25
      }
    };
    return super.watch(filter ? filter : defaultFilter).pipe(
      map((messages:Message[]) => messages.map((m:Message) => {
        try {
          m.content = decodeURIComponent(m.content);
          return m;
        } catch (e) {
          console.error('Error during message decoding:', e);
          return null;
        }
      }).filter(m => {
        return m !== null
      }))
    );
  }

  send(message: any) {
    let content:string = message.content;
    const div = document.createElement('div');
    div.innerHTML = content;
    content = div.innerText;
    content = this._sanitizer.sanitize(SecurityContext.HTML, message.content);
    message.content = encodeURIComponent(content);
    if (!content || (content.length === 0)) {
      return throwError('Malformed message!');
    }
    return super.create(message);
  }

}
