import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './guards/auth.guard';
import { PasswordGuard } from './password.guard';
// components
import { ChatComponent } from './components/chat/chat.component';
import { ChannelComponent } from './components/channel/channel.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { EventComponent } from './components/event/event.component';
import { FormBuilderComponent } from './components/form-builder/form-builder.component';
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/signup/signup.component';
import { SessionComponent } from './components/session/session.component';
import { IndustrieausstellerComponent } from './components/industrieaussteller/industrieaussteller.component';
import { InformationComponent } from './components/information/information.component';
import { TagungsbueroComponent } from './components/tagungsbuero/tagungsbuero.component';
import { SponsorComponent } from './components/sponsor/sponsor.component';
import { FormRendererComponent } from './components/form-renderer/form-renderer.component';
import { FormListComponent } from './components/form-list/form-list.component';
import { CookiePageComponent } from './components/cookie-page/cookie-page.component';
import { AboutComponent } from './components/about/about.component';
import { GeneralInformationComponent } from './components/general-information/general-information.component';
import { ProgramComponent } from './components/program/program.component';
import { RegistrationComponent } from './components/registration/registration.component';
import { AbstractSubmissionComponent } from './components/abstract-submission/abstract-submission.component';
import { IerasgComponent } from './components/ierasg/ierasg.component';
import { VenueComponent } from './components/venue/venue.component';
import { AwardsComponent } from './components/awards/awards.component';
import { AboutCologneComponent } from './components/about-cologne/about-cologne.component';
import { SocialProgramComponent } from './components/social-program/social-program.component';
import { AccomodationComponent } from './components/accomodation/accomodation.component';
import { SponsoringComponent } from './components/sponsoring/sponsoring.component';
import { LiveComponent } from './live/live.component';

/*
  Our app's routes.
  If you don't know what this means, check https://angular.io/docs/ts/latest/guide/router.html
 */
const routes: Routes = [
  {
    path: '',
    children: [
      {
        path: '',
        pathMatch: 'full',
        redirectTo: 'information'
      },
      // {
      //   path: 'about',
      //   component: AboutComponent
      // },
      {
        path: 'cookies',
        component: CookiePageComponent
      },
      // {
      //   path: 'chat',
      //   component: ChatComponent,
      //   canActivate: [AuthGuard]
      // },
      // {
      //   path: 'channel/:channelId',
      //   component: ChannelComponent,
      //   canActivate: [AuthGuard]
      // },
      // {
      //   path: 'dashboard',
      //   component: DashboardComponent,
      //   canActivate: [AuthGuard]
      // },
      {
        path: 'event/:eventId',
        component: EventComponent,
        // canActivate: [AuthGuard]
        canActivate: [PasswordGuard]
      },
      {
        path: 'event/:eventId/channel/:channelId',
        component: ChannelComponent,
        // canActivate: [AuthGuard]
      },
      {
        path: 'event/:eventId/session/:sessionId',
        component: SessionComponent,
        // canActivate: [AuthGuard]
      },
      {
        path: 'session/:sessionId',
        component: SessionComponent,
        canActivate: [AuthGuard]
      },
      // {
      //   path: 'surveys',
      //   component: FormListComponent,
      //   canActivate: [AuthGuard]
      // },
      // {
      //   path: 'survey/new',
      //   component: FormBuilderComponent,
      //   canActivate: [AuthGuard]
      // },
      // {
      //   path: 'survey/:surveyId',
      //   component: FormRendererComponent,
      //   canActivate: [AuthGuard]
      // },
      {
        path: 'information',
        component: InformationComponent,
      },
      {
        path: 'registration',
        component: RegistrationComponent,
      },
      {
        path: 'sponsoring',
        component: SponsorComponent,
      },
      {
        path: 'sponsors',
        component: SponsoringComponent,
      },
      // {
      //   path: 'industrieaussteller',
      //   component: SponsorComponent,
      // },
      // {
      //   path: 'industrieaussteller/aussteller',
      //   component: IndustrieausstellerComponent,
      // },
      // {
      //   path: 'industrieaussteller/aussteller/:sponsorID',
      //   component: IndustrieausstellerComponent,
      // },
      {
        path: 'conference-office',
        component: TagungsbueroComponent,
      },
      // {
      //   path: 'tagungsbuero',
      //   component: TagungsbueroComponent,
      // },
      {
        path: 'login',
        component: LoginComponent
      },
      //  {
      //   path: 'signup',
      //   component: SignupComponent
      // },
      {
        path: 'general-information',
        component: GeneralInformationComponent
      },
      {
        path: 'abstract-submission',
        component: AbstractSubmissionComponent
      },
      {
        path: 'program',
        component: ProgramComponent,
        // canActivate: [PasswordGuard]
      },
      {
        path: 'ierasg',
        component: IerasgComponent
      },
      {
        path: 'venue',
        component: VenueComponent
      },
      {
        path: 'about-cologne',
        component: AboutCologneComponent
      },
      {
        path: 'awards',
        component: AwardsComponent
      },
      {
        path: 'social-program',
        component: SocialProgramComponent
      },
      {
        path: 'accomodation',
        component: AccomodationComponent
      },
      {
        path: 'live',
        component: LiveComponent,
        canActivate: [AuthGuard]
      },
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    useHash: true,
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
