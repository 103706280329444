export class UploadInEvent {
    public session_id: string | null;
    public upload_id: string;
    public event_id: string;
    public _id: string;
    
    /**
     * UploadInEvent
     * @param upload_id 
     * @param event_id 
     * @param session_id 
     * @param _id 
     */
    constructor(upload_id: string, event_id: string, session_id: string, _id?:string) {
        this._id = _id;
        this.event_id = event_id;
        this.upload_id = upload_id;
        this.session_id = session_id;
        return this;
    }

}
