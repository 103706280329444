import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-social-program',
  templateUrl: './social-program.component.html',
  styleUrls: ['./social-program.component.scss']
})
export class SocialProgramComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
