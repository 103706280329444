import { Component } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker/ngx-bootstrap-datepicker';
// Services
import { SessionService } from '../../services/session/session.service';
import { Channel } from '../../services/channel/channel';

@Component({
  selector: 'vc-session-create-modal',
  templateUrl: './session-create-modal.component.html',
  styleUrls: ['./session-create-modal.component.scss']
})
export class SessionCreateModalComponent {
  channel: Channel;
  sessionName: string;
  streamUrl: string;
  stream_type: string;
  session_day: Date;
  session_start: Date;
  session_end: Date;
  enableChat: boolean = true;
  session_description: string;
  bsConfig: Partial<BsDatepickerConfig> = {
    containerClass: 'theme-default',
  };

  constructor(
    public bsModalRef: BsModalRef,
    private toastr: ToastrService,
    private sessionService: SessionService,
  ) {}

  validateSession() {
    let valid = true;
    const elements = [this.channel, this.sessionName, this.streamUrl, this.stream_type, this.session_day, this.session_start, this.session_end];
    for (let e of elements) {
      if (!e) {
        valid = false;
        break;
      }
    }
    return valid;
  }

  createSession() {
    if (!this.validateSession()) {
        this.toastr.warning('Not all required information is availabel.', 'Missing information.');
        return;
    }
    this.sessionService.create({
        event_id: this.channel.event_id,
        channel_id: this.channel._id,
        name: this.sessionName,
        description: this.session_description,
        day: this.session_day.getTime(),
        start_time: this.session_start.getTime(),
        end_time: this.session_end.getTime(),
        type: this.stream_type,
        chat_enabled: this.enableChat,
        stream_url: this.streamUrl
    }).subscribe(
        data => {
            this.toastr.success(`Created session "${this.sessionName}".`);
            this.bsModalRef.hide()
        },
        error => {
            this.toastr.error(`Could not create channel "${this.sessionName}".`, 'Error');
            console.error('[createSession@sessionCreate.modal]', error)
        }
    )
  }

}
