import { Injectable } from '@angular/core';
import { RouterEvent } from '@angular/router';
declare var window: any;

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  constructor() { }
  
  trackEvent(category: string, action:string, name: string = null, value:any = new Date().getTime(), event_type:string = 'click') {
    // matomo
    if (window._paq && category && action) {
      // _paq.push(['trackEvent', 'test', 'Clicked', new Date().getTime()]);
      //            matomo.event, event.category, event.action, event.name, event.value  
      // console.log(category, action, name, value)
      window._paq.push(['trackEvent', category, action, name]); //, value]);
    }  
    // umami
    if (window.umami) {
      // umami.trackEvent(event_value, event_type, [url], [website_id])
      window.umami.trackEvent([category, action, name].join(' - '), event_type);
    }
  }

  trackView(event: RouterEvent) {    
    if (window.umami) {
      window.umami.trackView(event.url);
    }
  }
}
