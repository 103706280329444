import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PasswordService } from './password.service';

@Injectable({
  providedIn: 'root'
})
export class PasswordGuard implements CanActivate {
  constructor(private passwordService: PasswordService, private router: Router){}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    const storedPassword = this.passwordService.getComponentPassword();
    const passwordKey = this.passwordService.getPassword();

    if (storedPassword) {
      return true;
    } else {
      const password = prompt('Please enter the password:');
      if (password === passwordKey) {
        this.passwordService.setComponentPassword(password);
        return true;
      } else {
        this.router.navigate(['/']); // Redirect to home page if password is incorrect
        return false;
      }
    }
  }

}
