import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'vc-information',
  templateUrl: './information.component.html',
  styleUrls: ['./information.component.scss']
})
export class InformationComponent implements OnInit {
  programmLink: string;

  constructor(
    private authService: AuthService,
  ) { }

  ngOnInit() {
    this.programmLink = this.authService.hasUser() ? '/event/VdoUjTaifwXRdVsI' : '/login';
    this.sortSponsorsAscending()
  }

  sponsors = [
    {
      id: 2,
      name: 'Intelligent Hearing Systems',
      logo: '../../../assets/2022/sponsor/ihs/ihs-logo.png',
      link: 'https://ihsys.info/site/en/',
      download: '',
      // video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: 'Founded in 1983, Intelligent Hearing Systems has been dedicated to the development of innovative methods and devices for diagnosis and treatment of hearing and neurological disorders for over 40 years! Our unsurpassed customer service and innovative, integrated systems are the Smart Choice for complete screening and diagnostic testing using evoked potentials (EcochG, ABR, eABR, ASSR, MLR, LLR P300, ACC, cVEMP, oVEMP, ENoG), otoacoustic emissions (DPOAE, TEOAE), and audiometry (IVRA) techniques.',
      product_description: 'Our flagship product, the Duet, is a sleek, portable, lightweight and versatile clinical EP and OAE system.  The Duet’s stellar performance comes from our next generation 2-channel bio-amplifier with a built-in isolation transformer that provides superior data quality with increased signal-to-noise (SNR) ratios, and lower residual noise, resulting in reduced test times without compromising data quality.  Options for using standard averaging, Bayesian weighted averaging or median averaging in real-time and objective quality measures such as Fsp, Fmp and d-prime, provide further noise reduction and quality evaluation tools. In addition to our clinical solutions, we also offer a variety of research options. The Advanced Auditory Research Module provides complete control of the stimulus and acquisition timeline, for acquiring responses to complex signals such as Frequency Following Response.  The P300 module can be used with up to 4 stimulus signals and interfaced with a video controller to conduct audio-visual interaction experiments.  We also offer the Continuous Loop Averaging Deconvolution (CLAD) technique, for deconvolving overlapping responses acquired at high stimulation rates, a Binaural Interaction Research Module as well as a Continuous Acquisition Module, SmartEP-CAM, for acquiring continuous EEG with evoked response averaging.',
      product_description2: 'In addition to our clinical solutions, we also offer a variety of research options. The Advanced Auditory Research Module provides complete control of the stimulus and acquisition timeline, for acquiring responses to complex signals such as Frequency Following Response.  The P300 module can be used with up to 4 stimulus signals and interfaced with a video controller to conduct audio-visual interaction experiments.  We also offer the Continuous Loop Averaging Deconvolution (CLAD) technique, for deconvolving overlapping responses acquired at high stimulation rates, a Binaural Interaction Research Module as well as a Continuous Acquisition Module, SmartEP-CAM, for acquiring continuous EEG with evoked response averaging.',
      contact1: 'Rafael E. Delgado, Ph.D.',
      contact2: 'Executive Vice President & Director of Research & Software Development',
      contact3: 'Intelligent Hearing Systems Corp.',
      contact_email: 'redelgado@ihsys.com ',
      contact_tel: '+1 (305) 668-6102',
      contact_fax: ' +1 (305) 668-6103',
      linking: true
    },

    {
      id: 1,
      name: 'PATH MEDICAL',
      logo: '../../../assets/2022/sponsor/patme/PATH_Logo_HF_4c.png',
      link: 'https://www.pathme.de/',
      download: '../../../assets/2022/sponsor/patme/1200849 PM Product Catalogue V5.pdf',
      // video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: 'PATH MEDICAL is a Germany based, innovative company that prides itself on cutting edge technologies to simplify the workflow for the healthcare professional. PATH MEDICAL has unique algorithms and technologies considered to be the new frontier in Audiology practice.',
      company_description2: 'Since 2007 PATH MEDICAL develops innovative solutions, which make audiological diagnoses simple for all ages. The team of PATH MEDICAL has a tremendous footprint in the industry since 1998 as they developed todays most famous Newborn Hearing Screeners.',
      company_description3: 'As the industry innovator, we use our solid knowledge and experience to create an innovative unique portfolio with ease of use solutions for health care professionals. Together with our partners, we combine efforts in providing better care for hearing detection at any age. We are dedicated to developing the best technology for hearing assessment. We are committed to helping and succeed through promotion of best practices, reliable solutions,trusted partnerships and knowledge in action.',
      product_description: '',
      product_description2: '',
      product_description3: '',
      product_description_img: '',
      contact1: 'Dr.-Ing. Dipl.-Kfm. Hans Oswald',
      contact2: 'Managing Director',
      contact3: 'PATH MEDICAL GmbH',
      contact_email: 'oswald@pathme.de',
      contact_tel: '+49  89  8007 6502',
      contact_mobile: '+49 176 6124 1781',
      contact_fax: '+49  89  8007 6503',
      linking: true
    },

    {
      id: 7,
      name: 'Cochlear',
      logo: '../../../assets/2022/sponsor/cochlear/Cochlear_Stacked_Yellow_sRGB.png',
      link: 'https://www.cochlear.com/',
      download: '../../../assets/2022/sponsor/cochlear/D1593196_5-2_CorporateCochlear_BCE_EN-GB_WEB.pdf',
      // video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: '',
      product_description: '',
      product_description2: '',
      contact1: 'Cochlear Deutschland GmbH & Co. KG',
      contact2: 'Mailänder Straße 4 a',
      contact3: '30539 Hannover',
      contact_email: 'kundenservice@cochlear.com',
      contact_tel: '+49 511 542 77-0',
      contact_fax: '+49 511 542 77-70',
      linking: true
    },

    {
      id: 4,
      name: 'Advanced Bionics',
      logo: '../../../assets/2022/sponsor/ab/AB_Logo_BLUE_300dpi.jpg',
      link: 'https://www.advancedbionics.com',
      download: '',
      // video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: '',
      product_description: '',
      product_description2: '',
      contact1: 'Dr. Raphael Koning',
      contact2: 'Sr. Manager R&T Europe',
      contact3: 'Advanced Bionics',
      contact3_address1: 'Feodor-Lynen-Straße 35',
      contact3_address2: '30625 Hannover, Germany',
      contact_email: 'Raphael.koning@advancedbionics.com',
      contact_tel: '+49 151 238 111 39',
      contact_fax: '',
      linking: true
    },


    {
      id: 8,
      name: 'MED-EL',
      logo: '../../../assets/2022/sponsor/medel/MED-EL_red_small.png',
      link: 'https://www.medel.com/',
      download: '../../../assets/2022/sponsor/medel/Hearing Loss Solutions Brochure - English (CE).pdf',
      // video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: '',
      product_description: '',
      product_description2: '',
      contact1: 'Lucas Lehning',
      contact2: 'Senior Regional Manager',
      contact3: 'MED-EL Deutschland GmbH',
      contact_email: 'Lucas.Lehning@medel.de',
      contact_tel: '+49 8151 7703 121',
      contact_mobile: '+49 163 7703 017',
      contact_fax: '',
      linking: true
    },


    {
      id: 6,
      name: 'Auritec',
      logo: '../../../assets/2022/sponsor/auritec/Auritec.png',
      link: 'https://www.auritec.de/',
      download: '../../../assets/2022/sponsor/auritec/Auritec_Broschuere.pdf',
      //video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: 'AURITEC , Germany\'s technology leader in audiometry, was founded in 1994 in Hamburg. Our focus is the clinical audiometry. The first PC-driven audiometers were produced by our company for the German market, and there was constant development ever since. We have been exported our systems in different countries for a couple of years. AURITEC has always developed and manufactured their products in Germany.  Thanks to our continuous cooperations with several institutions and experts we always work on quality improvement and unique functionalities. After having set up a golden standard in clinical audiometry with the AT1000, we just made a great step further in audiometry  with the first multichannel audiometer AT2000.',
      product_description: '',
      product_description2: '',
      contact1: 'Jan Mody',
      contact2: 'Owner/CEO',
      contact3: 'AURITEC Medizindiagnostische Systeme GmbH',
      contact3_address1: 'Dernauer Straße 12',
      contact3_address2: 'D-22047 Hamburg',
      contact_email: 'mody@auritec.de',
      contact_tel: '+49 179 215 963',
      contact_fax: '',
      linking: true
    },


    {
      id: 3,
      name: 'Pilot',
      logo: '../../../assets/2022/sponsor/pilot/PB_Logo_Final-02.jpg',
      link: 'https://www.pilot-blankenfelde.de/',
      download: '../../../assets/2022/sponsor/pilot/Pilot_Portfolio.pdf',
      //video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: '',
      product_description: '',
      product_description2: '',
      contact1: 'Rainer Thie',
      contact2: 'General manager',
      contact3: 'Pilot Blankenfelde GmbH',
      contact3_address1: 'Wilhelm-Grunwald-Straße 48-50',
      contact3_address2: 'D-15827 Blankenfelde',
      contact_email: 'admin@pilot-blankenfelde.de',
      contact_tel: '+49 3379 371865',
      contact_fax: '',
      linking: true
    },


    {
      id: 5,
      name: 'Diatec',
      logo: '../../../assets/2022/sponsor/diatec/Interacoustics_black_RGB.jpg',
      link: 'https://www.diatec-diagnostics.de/',
      //download: '../../../assets/2022/sponsor/diatec/',
      //video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: 'Interacoustics is a world-leading supplier of equipment for diagnostic audiology and balance testing. For more than 50 years, we have been providing hearing healthcare professionals with precise and fast tools to assess their patients’ hearing and balance. Interacoustics has an ambition to advance the field of audiology and to explore new technology and innovative solutions. We are driven by the wish to make a difference in hearing healthcare and create tomorrow’s diagnostic solutions based on ‘Science made smarter’. As the industry leader, we combine our global knowledge and experience to create strong local setups that support specific market needs. In Germany Sales and Service for Interacoustics is handled by Diatec Diagnostics GmbH.',
      product_description: 'Eclipse',
      product_description2: 'The Interacoustics Eclipse is a modular platform offering both AEP, ASSR, VEMP and OAE modules. It has many clinically relevant features and is an excellent blend of parameter flexibility and user-friendliness. With revolutionary technology including the CE-Chirp® stimulus, the Interacoustics Eclipse offers functionality for both early, middle, and late responses.',
      contact1: 'Diatec Diagnostics GmbH',
      contact2: 'Vertrieb und Service für Interacoustics A/S in Deutschland',
      contact3: 'Diatec Diagnostics GmbH',
      contact3_address1: 'Hohenbuschei-Allee 2',
      contact3_address2: '44309 Dortmund',
      contact_email: '',
      contact_tel: '+49 231 / 92 53 14 50',
      contact_fax: '',
      linking: true
    },

     {
      id: 11,
      name: 'Innoforce',
      logo: '../../../assets/2022/sponsor/innoforce/Logo_IF.png',
      link: 'https://entstatistics.com/',
      download: '',
      // video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: '',
      product_description: '',
      product_description2: '',
      contact1: '',
      contact2: '',
      contact3: '',
      contact_email: '',
      contact_tel: '',
      contact_fax: '',
      linking: false
    },

    {
      id: 9,
      name: 'Grason-Stadler',
      logo: '../../../assets/2022/sponsor/grason-stadler/GSI_Logo_Color.png',
      link: 'https://www.grason-stadler.com/',
      download: '../../../assets/2022/sponsor/grason-stadler/IERASG Sponsorship.pdf',
      //video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: "",
      product_description: '',
      product_description2: '',
      contact1: 'Daniel Morehead',
      contact2: 'Director of International Sales ',
      contact3: 'Grason-Stadler Inc. ',
      contact3_address1: '10395 West 70th St. Eden Prairie, MN 55344 USA',
      contact3_address2: '',
      contact_email: 'dnmr@grason-stadler.com',
      contact_tel: '+1 (952) 947-6062',
      contact_mobile: '+1 (952) 200 - 3042 ',
      contact_fax: '',
      linking: true
    },

    {
      id: 10,
      name: 'Zeisberg',
      logo: '../../../assets/2022/sponsor/zeisberg/Logo_Z_Shop_transparent.png',
      link: 'https://www.zeisberg.net/',
      // download: '../../../assets/2022/sponsor/',
      //video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: "",
      product_description: '',
      product_description2: '',
      contact1: 'Sven Zeisberg',
      contact2: 'General Manager',
      contact3: 'Zeisberg GmbH',
      contact3_address1: 'Gutenbergstraße 39',
      contact3_address2: '72555 Metzingen',
      contact_email: 'info@zeisberg.net',
      contact_tel: '07123 9769750',
      contact_mobile: '',
      contact_fax: '',
      linking: true
    },

    {
      id: 11,
      name: 'Jean Uhrmacher Stiftung',
      logo: '../../../assets/2022/sponsor/JUS/JUS_Blau_Vektoren.svg',
      link: '',
      // download: '../../../assets/2022/sponsor/',
      //video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
      company_description: "",
      product_description: '',
      product_description2: '',
      contact1: '',
      contact2: '',
      contact3: '',
      contact3_address1: '',
      contact3_address2: '',
      contact_email: '',
      contact_tel: '',
      contact_mobile: '',
      contact_fax: '',
      linking: true
    },




    // // Template
    // {
    //   id: "",
    //   name: '',
    //   logo: '',
    //   link: '',
    //   download: '',
    //   video: 'https://player.vimeo.com/video/830343328?h=be08b53df9&amp;badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479',
    //   company_description: '',
    //   product_description: '',
    //   product_description2: '',
    //   contact1: '',
    //   contact2: '',
    //   contact3: '',
    //   contact_email: '',
    //   contact_tel: '',
    //   contact_fax: '',
    //   linking: true
    // },

  ];

  sortSponsorsAscending() {
    this.sponsors.sort((a, b) => a.id - b.id);
  }

}
