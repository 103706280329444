import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PasswordService {
  private readonly componentPassword = 'ierasg2023VOD';

  getComponentPassword(): string {
    return sessionStorage.getItem(this.componentPassword);
  }

  setComponentPassword(password: string): void {
    sessionStorage.setItem(this.componentPassword, password);
  }

  clearComponenPassword(): void {
    sessionStorage.removeItem(this.componentPassword);
  }

  getPassword(): string {
    return this.componentPassword;
  }

  constructor() { }
}
