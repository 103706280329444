import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import exhibitors from '../../../assets/sponsoren.json';
import { Exhibitor } from './exhibitor';

@Injectable({
  providedIn: 'root'
})
export class ExhibitorService {
exhibitors: Exhibitor[]; 

  constructor() {
    this.exhibitors = exhibitors.exhibitors 
  }

  get(): Observable<Exhibitor[]> {
    return of(this.exhibitors);
  }

  getOne(id: number): Observable<Exhibitor> {
    return of(this.exhibitors.find(e => e.id === id));
  }

}
